export default
[
  'enlightenment',
  'spirituality',
  'meditation',
  'life coaching',
  'authenticity',
  'vulnerability',
  'truth',
  'assumptions',
  'personal development',
  'notthinkable',
  'notthinkable.com',
  'nick hayes',
  'spiritual coaching',
  'experience',
  'nick hayes life coach'
];
