import React from 'react'

import '../components/App.css';

import Layout from '../components/layout'
import SEO from '../components/seo'
import tags from '../components/tags'

import bg from '../images/bg.jpg'

const HomePage = () => (
  <Layout>
    <SEO title="Coaching" keywords={tags} />
    <div className="page">
      <img src={bg} className="background" alt=""/>
      <div className="page-content-container">
        <div className="page-content coaching-page-content">
          <p>I'm offering <span style={{fontWeight: 500}}>1-1 coaching and facilitation</span> to empower you to become free of limitations, and to transform yourself.</p>
          <p>If you love to work on yourself, engage in personal development, or have a meditative practice, then this is absolutely for you. I can support you in deepening, broading, and integrating your existing practice.</p>
          <p>If you're looking for clarity on what's possible for you, a vision for your life, or support with your goals, then I'd love to help you too!</p>
          <p style={{fontWeight: 500}}>You're warmly invited to a discovery session. This is a powerful hour long coaching experience where you'll see first-hand if this work is right for you. I always encourage people to try it once--it's so important to me that others can discover what's possible for them.</p>
          <div className="clickable-cards">
            <a className="card" href="https://calendly.com/notthinkable/1-1-session">
              <div className="discovery-box box-left">
                Book free discovery session
              </div>
            </a>
          </div>

          <p style={{fontWeight: 600, marginTop: '40px', fontSize: '18px'}}>FAQ</p>

          <p style={{fontWeight: 500}}>What does this look like?</p>
          <p>Unlike anything you've experienced before, especially if you're new to coaching! Over the course of the session, we'll be creating an intentional relationship together, one that is maximally empowering to you.</p>
          <p>And as the session unfolds, the possibilities will become more and more clear. No matter the specifics, it will be deeply aligned with your own goals and what matters most to you.</p>
          <p>The coaching relationship resembles other forms: life coaching, personal meditation instruction, mentoring, deep listening, and holding space. It’s always founded in honesty, openness, and empathy, and in an intention to empower you to become more free and conscious, and in creating the kind of life you'd love to live.</p>

          <p style={{fontWeight: 500}}>Where does this take place?</p>
          <p>Video call via Skype or Hangouts. In person is also available, for Londoners in the UK.</p>

        </div>
      </div>
    </div>
  </Layout>
)

export default HomePage
